import earth from '../assets/earth-1.jpg'
import moon from '../assets/mn-1.jpg'
import mars from '../assets/mars-1.jpg'

export const Data = [
    {
        img:earth
    },
    {
        img:moon
    },
    {
        img:mars
    },
]