import React from 'react';

const Nature = () => {
    return( 
        <div>
                <div>
                        <h1 className='section-header'>Find happinnes in the 
                        <span>Nature</span> 
                        </h1>

                        <p className='section-status'>
                        if you are struggling to be happy but can't on Earth, 
                        why not changing destination to the space, just take a look you wont regret it, trust us
                        </p>

                    </div>
        </div>
        );
};

export default Nature;
